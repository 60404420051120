import { useMemo } from "react";

import { useGetCustomerMiles } from "@/generated/open-api/customer/customer";
import { convertObjToCamelCase } from "@/utils/converters";

export const useParsedGetCustomerMiles = (page: number) => {
  const { data, ...rest } = useGetCustomerMiles({ page });

  const customerMile = useMemo(() => {
    if (!data || !data.data || !data.meta) return;

    const miles = data.data.map((mile) => convertObjToCamelCase(mile));
    const paginate = convertObjToCamelCase(data.meta);
    return { miles, paginate };
  }, [data]);

  return { data: customerMile, ...rest };
};
