export type StampTarget = {
  sku: string;
  variantId: string;
  title: string;
  isRegistered: boolean;
  fullName: string;
  name: string;
  isFreeze: boolean;
  isPurchased: boolean;
  image: string;
};

export const stampTargets = [
  {
    sku: "102012",
    variantId: "33022154375250",
    title: "BASE BREAD",
    isRegistered: true,
    fullName: "BASE BREAD ミニ食パン・プレーン",
    name: "ミニ食パン・プレーン",
    isFreeze: false,
    isPurchased: false,
    image: "thumb_bb_slice_plain_v1_5_omote",
  },
  {
    sku: "102013",
    variantId: "33022154375251",
    title: "BASE BREAD",
    isRegistered: true,
    fullName: "BASE BREAD ミニ食パン・レーズン",
    name: "ミニ食パン・レーズン",
    isFreeze: false,
    isPurchased: false,
    image: "thumb_bb_slice_raisin_v1_3_omote",
  },
  {
    sku: "105001",
    variantId: "41330352562201",
    title: "BASE Pancake Mix",
    isRegistered: false,
    fullName: "BASE Pancake Mix パンケーキミックス",
    name: "パンケーキミックス",
    isFreeze: false,
    isPurchased: false,
    image: "thumb_bpm_v1_2_omote",
  },
  {
    sku: "111002",
    variantId: "40330352562206",
    title: "BASE PASTA",
    isRegistered: true,
    fullName: "BASE PASTA たらこ",
    name: "たらこ",
    isFreeze: true,
    isPurchased: false,
    image: "thumb_bp_frozen_tarako_v1_1",
  },
  {
    sku: "111003",
    variantId: "40330352562207",
    title: "BASE PASTA",
    isRegistered: true,
    fullName: "BASE PASTA きのこクリーム",
    name: "きのこクリーム",
    isFreeze: true,
    isPurchased: false,
    image: "thumb_bp_frozen_mushroom_v1_1",
  },
  {
    sku: "111004",
    variantId: "40330352562204",
    title: "BASE PASTA",
    isRegistered: true,
    fullName: "BASE PASTA ソース焼きそば",
    name: "ソース焼きそば",
    isFreeze: false,
    isPurchased: false,
    image: "thum_bp_yakisoba_v1_2_zentai",
  },
  {
    sku: "111005",
    variantId: "40330352562208",
    title: "BASE PASTA",
    isRegistered: true,
    fullName: "BASE PASTA 旨辛まぜそば",
    name: "旨辛まぜそば",
    isFreeze: false,
    isPurchased: false,
    image: "thum_bp_mazesoba_v1_0_zentai",
  },
];
