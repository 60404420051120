"use client";

import { useState } from "react";

import clsx from "clsx";
import { format } from "date-fns";
import Link from "next/link";

import Pagination from "@/components/displays/Pagination";
import { useParsedGetCustomerMiles } from "@/queries/customer/useParsedGetCustomerMiles";
import { numberFormat } from "@/utils";

import styles from "./MileHistory.module.scss";

export function MileHistory(): React.ReactNode {
  const [page, setPage] = useState(1);
  const { data } = useParsedGetCustomerMiles(page);
  const miles = data?.miles;
  const paginate = data?.paginate;
  const totalPage = paginate?.lastPage || 0;

  return (
    <section className="bg__gray">
      <div className="container pd__top__xl pd__bottom__xl">
        <div className="row mg__bottom__l">
          <div className="col-12 col-m-10 col-offset-m-1">
            <h3 className="text__xxl text__bold text__center mg__bottom__l">マイル履歴</h3>

            <div>
              {!miles || miles.length === 0 ? (
                <p className="text__m text__center mg__bottom__m">マイル履歴が存在しません</p>
              ) : (
                <>
                  {miles.map((mile, index) => (
                    <dl className={styles.history} key={index}>
                      <dt>
                        <span className={clsx("text__m", styles.date)}>
                          {mile.createdAt ? format(mile.createdAt, "yyyy/MM/dd") : ""}
                        </span>
                        <span className="text__m text__bold">{mile.label}</span>
                      </dt>
                      <dd>
                        <p className="text__l text__bold">{`${mile.mile >= 0 ? "+" : ""}${numberFormat(mile.mile)}`}</p>
                      </dd>
                    </dl>
                  ))}
                </>
              )}
              <div className="mg__top__m">
                <Pagination onPageChange={setPage} totalPage={totalPage} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text__center">
            <Link href="/mypage" className="btn inline round border__bk angle__left">
              <i className="fas fa-angle-left"></i>
              マイページに戻る
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
