"use client";

import clsx from "clsx";
import Link from "next/link";

import { useAuth } from "@/components";
import { Image, QRCode } from "@/components/displays";
import { CopyUrlBox } from "@/components/template/EcInvite/CopyUrlBox";
import { SnsShareBox } from "@/components/template/EcInvite/SnsShareBox";

import styles from "./JustNowShareSection.module.scss";
import { PointHistory } from "./PointHistory";

interface JustNowShareSectionProps {
  shareUrl: string;
  qrUrl: string;
}

export function JustNowShareSection({
  shareUrl,
  qrUrl,
}: JustNowShareSectionProps): React.ReactNode {
  const { isLoggedIn } = useAuth();

  return (
    <section id="share" className="bg__gray">
      <div className="container pd__bottom__xl">
        <div className="row">
          <div className="col-12 col-m-10 col-offset-m-1">
            <div className={clsx("bg__yellow mg__top__xl", styles.shareBox)}>
              <Image
                src="https://asset.basefood.co.jp/images/bnr/bnr_invite_title.png"
                alt="いますぐベースフードをシェア！"
                aspectRatio="56/17"
                containerClassName={styles.image}
              />
              <div className="row mg__top__l">
                <div className="col-12 col-m-8 col-offset-m-2">
                  <div className="mg__bottom__l">
                    <SnsShareBox shareUrl={shareUrl} />
                  </div>
                  <p className="text__s text__bold mg__bottom__s clear__pc">紹介QRコード・URL</p>
                  <div
                    className="text__center bg__white mg__bottom__s pd__top__m pd__bottom__m clear__pc"
                    style={{ borderRadius: "4px" }}
                  >
                    <p className="text__l text__bold mg__bottom__s">QRでかんたん紹介</p>
                    <QRCode url={qrUrl} />
                  </div>
                  <div className="mg__bottom__l">
                    <CopyUrlBox shareUrl={shareUrl} />
                  </div>
                  <p className="text__s mg__bottom__l">
                    ※
                    ポイントの上限は100,000ポイントとなります。ポイント上限に達した場合、新たに獲得したポイントは保存できませんので、ご了承ください。※
                    SNSへ投稿する際、当社のSNS広告(Facebook、Xなど)にリプライ・コメントする形で、友だち紹介URLを添付することは禁止しております。※
                    これに該当する場合、ポイントを無効とさせていただきますので、ご了承ください。
                  </p>
                </div>
              </div>
              <div className="text__center">
                <Link href="/terms" className="btn inline round gray" target="_blank">
                  詳しくはこちら
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row mg__top__xl mg__bottom__xl">
          <div className="col-12 col-m-10 col-offset-m-1">
            <div className={styles.note}>
              <h3 className="text__xl text__bold text__center text__yellow mg__bottom__l">
                注意事項
              </h3>
              <ul className="text__s">
                <li className={styles.noteItem}>
                  紹介リンクから購入しても、個人情報は双方に通知されません。
                </li>
                <li className={styles.noteItem}>送料や代引き手数料は割引対象外です。</li>
                <li className={styles.noteItem}>
                  ご紹介は何回でも可能ですが、同じ方に複数回紹介することはできません。
                </li>
                <li className={styles.noteItem}>
                  ご紹介は、ベースフード継続コース(旧定期便含む)がはじめての方にのみ可能です。過去継続コースをご購入いただいたことのある方にはご紹介できませんので、ご注意ください。
                </li>
                <li className={styles.noteItem}>
                  ポイントの保有上限は100,000ptまでです。これを超えるポイントは加算されませんので、ご注意ください。
                </li>
                <li className={styles.noteItem}>
                  ポイントの有効期限は、最後にポイントが付与されてから2年間です。
                </li>
                <li className={styles.noteItem}>
                  本紹介制度は予告なく変更する場合がありますので予めご了承ください。
                </li>
              </ul>
              <div className="text__right mg__top__s">
                <Link href="/terms" className={styles.textLink} target="_blank">
                  利用規約↗
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row mg__top__xl mg__bottom__l">
          <div className="col-12 col-m-10 col-offset-m-1">
            <h3 className="text__xxl text__bold text__center mg__bottom__l">ポイント履歴</h3>
            {isLoggedIn && <PointHistory />}
          </div>
        </div>

        <div className="row">
          <div className="col-12 text__center">
            <a href="/mypage" className="btn inline round border__bk angle__left">
              <i className="fas fa-angle-left"></i>
              マイページに戻る
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
