import clsx from "clsx";

import { Scroll } from "@/components/displays";

import styles from "./CopyShareUrlButton.module.scss";

export function CopyShareUrlButton(): React.ReactNode {
  return (
    <Scroll to="share" className={clsx("btn inline round yellow", styles.fixed)}>
      紹介用URLをコピーする
    </Scroll>
  );
}
