"use client";

import { useMemo } from "react";

import clsx from "clsx";
import Link from "next/link";

import { Breadcrumb, Image } from "@/components";
import { ProductAdditionLink } from "@/components/template/campaign/ShoppingNewItems/ProductAdditionLink";
import { useGetCustomerOrdersStampsProducts } from "@/generated/open-api/customer/customer";

import { stampTargets } from "./const";
import styles from "./ShoppingNewItems.module.scss";
import { StampBox } from "./StampBox";
import { TargetItem } from "./TargetItem";

export function ShoppingNewItems(): React.ReactNode {
  const { data } = useGetCustomerOrdersStampsProducts();
  const variantIds = data?.stamp_achievements;

  const targetItems = useMemo(() => {
    if (!variantIds) return stampTargets;

    return stampTargets.map((target) => {
      return { ...target, isPurchased: variantIds.includes(target.variantId) };
    });
  }, [variantIds]);

  const normalVariantIds = useMemo(
    () =>
      targetItems
        .filter((target) => !target.isPurchased && !target.isFreeze)
        .map((target) => target.variantId),
    [targetItems]
  );
  const freezeVariantIds = useMemo(
    () =>
      targetItems
        .filter((target) => !target.isPurchased && target.isFreeze)
        .map((target) => target.variantId),
    [targetItems]
  );
  const isNormalFilled = normalVariantIds.length === 0;
  const isFreezeFilled = freezeVariantIds.length === 0;

  return (
    <div>
      <section className={clsx("bg__yellow", styles.root)}>
        <div className="container">
          <Breadcrumb
            items={[
              { path: "/", title: "HOME" },
              { path: "/mypage", title: "マイページ" },
            ]}
            current="新商品買いまわりキャンペーン"
            textClassName={styles.breadcrumbText}
          />
        </div>
        <div className={styles.heroImage}></div>
      </section>

      <section className="bg__white">
        <div className="container">
          <div className="row">
            <div className="col-12 col-m-6 col-offset-m-3 pd__top__l pd__bottom__l">
              <StampBox stamps={variantIds} />

              <p className="text__l text__bold text__center mg__top__l mg__bottom__m">対象商品</p>

              <div className={styles.targetItems}>
                {targetItems.map((target) => (
                  <TargetItem item={target} key={target.variantId} />
                ))}
                {[1, 2, 3].map((i) => (
                  <Image
                    src={`https://asset.basefood.co.jp/images/pkg/pkg_secret_0${i}.png`}
                    alt={`シークレット商品${i}`}
                    size={{ width: 80, height: 80 }}
                    containerClassName={styles.item}
                    key={i}
                  />
                ))}
              </div>

              <div className="text__center mg__top__l">
                {isNormalFilled && isFreezeFilled ? (
                  <>
                    <Image
                      src="https://asset.basefood.co.jp/images/parts/img_stamp_complete.png"
                      alt="コンプリート"
                      size={{ width: 120, height: 110 }}
                    />
                    <p className="text__m text__red__dark text__center text__bold mg__top__m mg__bottom__s">
                      全商品の買いまわりを達成しました！
                      <br />
                      おめでとうございます！
                    </p>
                    <p className="text__s">
                      新商品が追加されるとスタンプの枠が増え、
                      <br />
                      再度スタンプ（ポイント）を集めることができます。
                    </p>
                  </>
                ) : (
                  <>
                    {isNormalFilled && (
                      <p
                        className={clsx(
                          "text__m text__red__dark text__center text__bold mg__bottom__s",
                          styles.stampClear
                        )}
                      >
                        常温便の全商品買いまわりを
                        <br />
                        達成しました！
                      </p>
                    )}
                    <ProductAdditionLink
                      temperature={"normal"}
                      variantIds={normalVariantIds}
                      isFilled={isNormalFilled}
                    />
                    <div className="mg__bottom__s" />
                    {isFreezeFilled && (
                      <p
                        className={clsx(
                          "text__m text__red__dark text__center text__bold mg__bottom__s  mg__top__s",
                          styles.stampClear
                        )}
                      >
                        冷凍便の全商品買いまわりを
                        <br />
                        達成しました！
                      </p>
                    )}
                    <ProductAdditionLink
                      temperature={"freeze"}
                      variantIds={freezeVariantIds}
                      isFilled={isFreezeFilled}
                    />
                  </>
                )}
              </div>

              <p className="text__s text__gray__dark mg__top__l">
                ※キャンペーン期間は2024年4月24日〜2024年7月31日です。（7月31日注文確定分まで対象）
                <br />
                ※スタンプの反映は注文内容変更後ではなく、注文確定後です。また、コンビニ・銀行振込の場合はお支払い確定後です。
                <br />
                ※キャンペーン対象は、対象商品（BASE BREAD
                <sup className="sup">
                  <i className="far fa-registered" />
                </sup>
                こしあん、ミニ食パン・プレーン、ミニ食パン・レーズン、BASE Pancake Mix™︎
                パンケーキミックス、BASE Cookies
                <sup className="sup">
                  <i className="far fa-registered" />
                </sup>
                ココア、ペッパー、チーズ、BASE PASTA
                <sup className="sup">
                  <i className="far fa-registered" />
                </sup>
                ソース焼きそば、旨辛まぜそば、たらこ、きのこクリーム）のご購入に限ります。
                <br />
                ※アウトレット商品は、本キャンペーンの対象外となります。
                <br />
                ※継続コースへの追加での購入、1回のみ購入の場合でも、対象商品1種類につき1個のスタンプが付与されます。
                <br />
                ※キャンペーン開始前に発売したBASE BREAD
                <sup className="sup">
                  <i className="far fa-registered"></i>
                </sup>
                ミニ食パン・プレーン、ミニ食パン・レーズンは2024年3月12日以降、BASE PASTA
                <sup className="sup">
                  <i className="far fa-registered"></i>
                </sup>
                たらこ、きのこクリームは2024年3月21日以降、BASE PASTA
                <sup className="sup">
                  <i className="far fa-registered"></i>
                </sup>
                ソース焼きそばは2024年4月3日以降の注文確定分に遡ってスタンプを自動で付与しております。
                <br />
                ※同じ種類の商品を複数個購入した場合でも、スタンプは1個のみ付与されます。
                <br />
                ※ポイントの付与は、スタンプが所定の数貯った時点で自動で付与されます。
                <br />
                ※返品・キャンセルをした場合、ポイントは取り消しとなります。
              </p>

              <div className="text__center pd__top__l">
                <Link href="/mypage" className="btn inline round border__bk angle__left">
                  <i className="fas fa-angle-left"></i>
                  マイページに戻る
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
