"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";
import { Swiper as SwiperClass } from "swiper";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image, Scroll } from "@/components";

import styles from "./PlanSection.module.scss";

export function PlanSection(): React.ReactNode {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const slideTo = useCallback((index: number) => swiper?.slideTo(index), [swiper]);

  return (
    <section className={clsx("bg__gray", styles.overflowHidden)}>
      <div className="container pd__top__xl pd__bottom__xl">
        <div className="row">
          <div className="col-12 col-m-10 col-offset-m-1">
            <h4 className="text__center mg__bottom__l clear__sp">
              <Image
                src="https://asset.basefood.co.jp/images/parts/txt_pointpack_save.png"
                alt="ポイント購入で食事になやむ時間もお金も節約"
                size={{ width: 476, height: 144 }}
              />
            </h4>
            <h4 className="text__center mg__bottom__l clear__pc">
              <Image
                src="https://asset.basefood.co.jp/images/parts/txt_pointpack_save_sp.png"
                alt="ポイント購入で食事になやむ時間もお金も節約"
                size={{ width: 319, height: 175 }}
              />
            </h4>

            <h5 className="text__center mg__bottom__m clear__sp">
              <Image
                src="https://asset.basefood.co.jp/images/parts/txt_pointpack_choices.png"
                alt="選べる２つのプラン"
                size={{ width: 400, height: 60 }}
              />
            </h5>
            <h5 className="text__center mg__bottom__m clear__pc">
              <Image
                src="https://asset.basefood.co.jp/images/parts/txt_pointpack_choices.png"
                alt="選べる２つのプラン"
                size={{ width: 280, height: 42 }}
              />
            </h5>
          </div>
          <div className="col-12 clear__sp pd__top__m">
            <div className={styles.planTable}>
              <div className={styles.plan}>
                <Image
                  src="https://asset.basefood.co.jp/images/parts/img_pointpack_plan_01_0313.png"
                  alt="ライトプラン"
                  size={{ width: 392, height: 809 }}
                />
                <Scroll
                  to="yearplanPurchase"
                  className={clsx("btn round yellow text__m", styles.planLink)}
                >
                  ライトプランで購入する
                </Scroll>
              </div>
              <div className={styles.plan}>
                <Image
                  src="https://asset.basefood.co.jp/images/parts/img_pointpack_plan_02_0313.png"
                  alt="レギュラープラン"
                  size={{ width: 392, height: 809 }}
                />
                <Scroll
                  to="yearplanPurchase"
                  className={clsx("btn round yellow text__m", styles.planLink)}
                >
                  レギュラープランで購入する
                </Scroll>
              </div>
            </div>
          </div>

          <div className="col-12 mg__bottom__l clear__pc">
            <div className={styles.toggleBox}>
              <span
                className={clsx(
                  "text__center text__bold",
                  styles.toggleButton,
                  activeIndex === 0 && styles.toggleOn
                )}
                onClick={() => slideTo(0)}
              >
                <span className="text__s"> はじめての方向け</span>
                <br /> <span className="text__m">ライトプラン</span>
              </span>
              <span
                className={clsx(
                  "text__center text__bold",
                  styles.toggleButton,
                  activeIndex === 1 && styles.toggleOn
                )}
                onClick={() => slideTo(1)}
              >
                <span className="text__s"> はじめての方向け</span>
                <br /> <span className="text__m">ライトプラン</span>
              </span>
            </div>
          </div>

          <Swiper
            pagination={{
              clickable: true,
              bulletActiveClass: styles.paginationBullet,
            }}
            modules={[Pagination]}
            spaceBetween={20}
            slidesPerView={1}
            className="clear__pc"
            onSwiper={(swiper) => {
              setSwiper(swiper);
            }}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex);
            }}
          >
            <SwiperSlide key={1} className={styles.planSlide}>
              {/* display:block要素がないと画像幅が0になるので追加 */}
              <div className={styles.dummyBlock}>ライトプラン</div>
              <Image
                src="https://asset.basefood.co.jp/images/parts/img_pointpack_plan_sp_01_0313.png"
                alt="ライトプラン"
                aspectRatio="226/557"
              />
              <Scroll
                to="yearplanPurchase"
                className={clsx("btn inline round yellow text__m", styles.planSlideLink)}
              >
                ライトプランで購入する
              </Scroll>
            </SwiperSlide>
            <SwiperSlide key={2} className={styles.planSlide}>
              {/* display:block要素がないと画像幅が0になるので追加 */}
              <div className={styles.dummyBlock}>レギュラープラン</div>
              <Image
                src="https://asset.basefood.co.jp/images/parts/img_pointpack_plan_sp_02_0313.png"
                alt="レギュラープラン"
                aspectRatio="226/557"
              />
              <Scroll
                to="yearplanPurchase"
                className={clsx("btn inline round yellow text__m", styles.planSlideLink)}
              >
                レギュラープランで購入する
              </Scroll>
            </SwiperSlide>
          </Swiper>

          <div className="col-12 col-m-10 col-offset-m-1">
            <p className="text__m mg__bottom__m mg__top__l text__gray__dark">
              ※1 常温配送の送料500円
              <br />
              ※2 継続コース2回目以降（通常価格から10%オフ）BASE
              BREAD®ミニ食パン・プレーン229円、プレーン209円、チョコレート229円、メープル229円（1袋あたり・税込）。BASE
              BREAD®16袋セット（各4袋）を5回注文した場合、合計20,420円（送料込）。ポイントは1ポイント=1円分のため、ポイントパックライトで購入可能。
              <br />
              ※3 継続コース2回目以降（通常価格から10%オフ）BASE
              BREAD®ミニ食パン・プレーン229円、プレーン209円、チョコレート229円、メープル229円（1袋あたり・税込）。BASE
              BREAD®
              32袋セット（各8袋）を9回注文した場合、合計69,012円（送料込）。ポイントは1ポイント=1円分のため、ポイントパックレギュラーで購入可能。
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
