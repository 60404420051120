import React from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./ShareSection.module.scss";
import { CopyUrlBox } from "../CopyUrlBox";
import { SnsShareBox } from "../SnsShareBox";

interface ShareSectionProps {
  shareUrl: string;
}

export function ShareSection({ shareUrl }: ShareSectionProps): React.ReactNode {
  return (
    <section className={styles.root}>
      <div className="container pd__top__l pd__top__xl__pc">
        <div className="row">
          <div className="col-12 col-m-6 col-offset-m-3">
            <div className={styles.shareBox}>
              <p className={styles.shareText}>友だちにシェアしてみよう！</p>
              <SnsShareBox shareUrl={shareUrl} />
              <p className={styles.shareUrl}>紹介URL</p>
              <CopyUrlBox shareUrl={shareUrl} />
            </div>

            <div className={clsx(styles.shareDescription, "mg__bottom__l")}>
              <p
                className={clsx("text__m text__bold text__center mg__bottom__s", styles.shareTitle)}
              >
                友だち紹介制度とは
              </p>
              <p className="text__s">
                ご友人やご家族にベースフードを紹介すると、ポイントやマイルを獲得できるお得な制度です。ひとりに紹介するごとに、
                <span className="text__bold">1,000円分のポイント</span>
                と、
                <span className="text__bold">1,000マイル</span>
                が付与されます。
              </p>
            </div>
          </div>

          <div className="col-12 col-m-10 col-offset-m-1">
            <Image
              src="https://asset.basefood.co.jp/images/bnr/bnr_invite_merit_pc_0115.png"
              alt="次回の継続コースで利用可能な1,000円付与"
              containerClassName="clear__sp"
              aspectRatio="1218/1091"
            />
            <Image
              src="https://asset.basefood.co.jp/images/bnr/bnr_invite_merit_sp_0115.png"
              alt="友だちの継続コース初回が2,000円オフ"
              containerClassName="clear__pc"
              aspectRatio="223/370"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
