"use client";

import clsx from "clsx";
import { Link } from "react-scroll";

import { Image } from "@/components/displays";

import styles from "./BenefitLink.module.scss";

interface BenefitLinkProps {
  to: string;
  imageUrl: string;
}

export function BenefitLink({ to, imageUrl }: BenefitLinkProps): React.ReactNode {
  return (
    <Link className={clsx(styles.mileRankPrivilege, "mg__top__m")} to={to} smooth>
      <Image
        src={imageUrl}
        alt="特典リンク"
        aspectRatio="638 / 97"
        containerClassName={styles.image}
      />
    </Link>
  );
}
