import { useCallback } from "react";

import { CartModel } from "@/models/cart/type";
import { useClientVuex } from "@/storage";
import { NuxtCommonOrder } from "@/storage/types";
import { pushGtmEvent } from "@/utils/gtm";
import { setFromPath } from "@/utils/object";

type TriggerFromPage = (params: {
  searchParamFrom: string;
  order: NuxtCommonOrder;
  cart: CartModel;
}) => void;
export const triggerFromPage: TriggerFromPage = ({ searchParamFrom, order, cart }) => {
  const eventName = `${searchParamFrom}-purchase`;

  try {
    pushGtmEvent({
      event: eventName,
      eventModel: {
        currency: "JPY",
        value: Number(order.total_price),
        line_value: Number(cart.lineTotalPrice),
        total_shipping_fee: Number(cart.totalShippingFee),
        transaction_id: order.id,
        variant_title_string: cart.variantTitleString,
        total_meal_num: cart.totalMealNum,
        has_subscription: cart.hasSubscription,
        payment_method: cart.paymentMethod,
        user_data: {
          customer_id: order.customer?.id,
          email_address: order.email,
          phone_number: order.shipping_address.phone,
          address: {
            first_name: order.shipping_address.first_name,
            last_name: order.shipping_address.last_name,
            city: order.shipping_address.city,
            region: order.shipping_address.province,
            postal_code: order.shipping_address.zip,
            country: "JP",
          },
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

type CompleteAnalytics = (params: { order: NuxtCommonOrder; cart: CartModel }) => string[];
export const completeAnalytics: CompleteAnalytics = ({ order, cart }) => {
  const completeCartItems = [];
  const analyticsProducts = [];
  const payseCVProducts = [];
  for (const product of order.products) {
    completeCartItems.push(product.variant_id.toString());
    analyticsProducts.push([
      product.id?.toString(),
      (product.title ?? "") + (product.variant_id ?? ""),
      product.price,
      product.quantity,
    ]);
    payseCVProducts.push({
      item_id: product.id?.toString(),
      item_name: (product.title ?? "") + product.variant_title,
      first_subscription_price: product.first_subscription_price,
      variant_title: product.variant_title,
      quantity: product.quantity,
      price: Number(product.price) * (product?.quantity ?? 0),
    });
  }

  const items = analyticsProducts;
  // @if(config("app.env") === "production")
  let productObj = {};
  const productArray = [];
  for (let c = 0; c < items.length; c++) {
    const productId = items[c][0];
    const productName = items[c][1];
    const salesPrice = items[c][2];
    const qty = items[c][3];
    // const brand = items[c][4];
    // const category = items[c][5];

    productObj = {
      id: productId,
      name: productName,
      price: salesPrice,
      quantity: qty,
    };
    productArray.push(productObj);
  }

  const transactionId = order.id; // 必須：トランザクションID
  const totalPrice = order.total_price; // 必須：売上合計
  const tax = 0; // 任意：税金
  // vueのソースコードはorderから取っているが、精査したところ、orderにはshipping_feeがないため、cartから取得する
  const shipping = cart.totalShippingFee; // 任意：送料
  const affiliation = cart.isSubscription; // 任意：通常購入か継続コースか
  const coupon = order.coupon; // 任意：適用クーポン

  try {
    const eventData = {
      event: "",
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId, // 必須
            revenue: totalPrice, // 必須
            affiliation: affiliation, // 任意
            tax: tax, // 任意
            shipping: shipping, // 任意
            coupon: coupon, // 任意
          },
          products: productArray,
        },
      },
      currency: "JPY",
      value: Number(order.total_price),
      transaction_id: order.id,
      user_data: {
        email_address: order.email,
        phone_number: order.shipping_address.phone,
        address: {
          first_name: order.shipping_address.first_name,
          last_name: order.shipping_address.last_name,
          city: order.shipping_address.city,
          region: order.shipping_address.province,
          postal_code: order.shipping_address.zip,
          country: "JP",
        },
      },
      items: payseCVProducts,
      eventModel: {
        currency: "JPY",
        value: Number(order.total_price),
        line_value: Number(cart.lineTotalPrice),
        total_shipping_fee: Number(cart.totalShippingFee),
        transaction_id: order.id,
        variant_title_string: cart.variantTitleString,
        total_meal_num: cart.totalMealNum,
        has_subscription: cart.hasSubscription,
        payment_method: cart.paymentMethod,
        user_data: {
          customer_id: order.customer?.id,
          email_address: order.email,
          phone_number: order.shipping_address.phone,
          address: {
            first_name: order.shipping_address.first_name,
            last_name: order.shipping_address.last_name,
            city: order.shipping_address.city,
            region: order.shipping_address.province,
            postal_code: order.shipping_address.zip,
            country: "JP",
          },
        },
        items: payseCVProducts,
      },
    };

    eventData.event = "purchase";
    pushGtmEvent(eventData);

    // 差し替えて再度実行
    eventData.event = "payse-purchase";
    pushGtmEvent(eventData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  return completeCartItems;
};

export const completeSN = () => {
  try {
    const SmartnewsAds = { id: "b7kin", event: "" };
    const script = document.createElement("script");
    script.src = "//api.smartnews-ads.com/assets/conv.js?" + new Date().getTime();
    script.type = "text/javascript";
    script.id = "_smartnews_ads_conversion_script_" + SmartnewsAds["id"];
    script.setAttribute("data-smartnews-ads", JSON.stringify(SmartnewsAds));
    (document.body || document.head).appendChild(script);
  } catch {}
};

export const completeCocoda = (orderId: string) => {
  const uqid = "5b54x545d5758ce2";
  const gid = "90";
  const uid = orderId;
  const uid2 = "";
  const af = "";
  const pid = "";
  const amount = "";

  const a = document.createElement("script");
  a.src = "//ad.labecca.tokyo/ac/action_js.php";
  a.id = "afadaction-" + Date.now();
  a.addEventListener("load", function () {
    // @ts-expect-error fpcActionの定義が不明
    new fpcAction(a.id).groupAction(gid, af, [uid, uid2], pid, amount, uqid);
  });
  document.head.appendChild(a);
};

/**
 * TODO: localStorageに関する処理を1箇所にまとめる
 */
export const useClearStorage = () => {
  const { vuex, setVuex } = useClientVuex();
  return useCallback(() => {
    const clearSettings = {
      "cart.cart": {},
      "cart.form": {},
      "cart.order": {},
      "cart.validateResult": { validate: 0 },
      paymentData: {},
      "front-store.cartItems": [],
      "front-store.setNames": [],
    };

    if (vuex) {
      const parsedData = vuex ?? {};
      Object.entries(clearSettings).forEach(([path, value]) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFromPath(parsedData, path as any, value);
      });
      setVuex(parsedData);
    }
  }, [setVuex, vuex]);
};
