"use client";

import clsx from "clsx";

import "@/style/rankColor.scss";
import { Image } from "@/components";
import { MileRankInfo } from "@/components/domains/mypage";
import { RankNameTransformed } from "@/generated/open-api/schemas";
import { useParsedGetPointMile } from "@/queries/customer/useParsedGetPointMile";

import styles from "./RankSection.module.scss";

export function RankSection(): React.ReactNode {
  const { data: miles } = useParsedGetPointMile();
  const rankName = miles?.rankName || RankNameTransformed.Bronze;

  return (
    <section className={clsx("bg__gray", styles.container)}>
      <Image
        src="https://asset.basefood.co.jp/images/parts/icon_shine_yellow.svg"
        containerClassName={clsx(
          styles.mileProgramDashboardShine,
          rankName === "Gold" && styles.shineGold
        )}
        size={{ width: 26, height: 30 }}
        alt="star"
      />
      <Image
        src="https://asset.basefood.co.jp/images/parts/icon_shine_yellow.svg"
        containerClassName={styles.mileProgramDashboardShine}
        size={{ width: 26, height: 30 }}
        alt="star"
      />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mg__top__m mg__bottom__l pd__top__s">
              <MileRankInfo />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
