import React from "react";

import { MemberVoice } from "./MemberVoice";
import styles from "./MemberVoiceSection.module.scss";

const voices = [
  {
    src: "https://asset.basefood.co.jp/images/parts/illust_member_01.png",
    srcSet: "https://asset.basefood.co.jp/images/parts/webp/illust_member_01.webp",
    alt: "20代女性",
    title: (
      <>
        <span className="wsnr">ダイエットに成功し、</span>
        <wbr />
        <span className="wsnr">ジム仲間に</span>
      </>
    ),
    description:
      "置き換えダイエットを目的にBASE FOODを食べはじめました。間食が減ったことやジム通いの効果もあり、減量に成功！ジム仲間や職場の同僚に紹介制度をつかってシェアしています！",
  },
  {
    src: "https://asset.basefood.co.jp/images/parts/illust_member_02.png",
    srcSet: "https://asset.basefood.co.jp/images/parts/webp/illust_member_02.webp",
    alt: "30代男性",
    title: "自然由来の食材がつかわれているので、安心",
    description:
      "数年前からジムに通っているのですが、食欲が抑えられず、食事を見直したくてはじめました！小腹が空いたらBASE Cookies®︎を食べるようにしています。昆布や大豆粉など実際の食材がメインでつかわれているところがとても安心ポイントで、会社の後輩にすすめたところ一緒にBASE FOODユーザーに！",
  },
  {
    src: "https://asset.basefood.co.jp/images/parts/illust_member_03.png",
    srcSet: "https://asset.basefood.co.jp/images/parts/webp/illust_member_03.webp",
    alt: "50代女性",
    title: (
      <span>
        いそがしい朝にぴったり
        <br />
        ママ友におすすめしてます
      </span>
    ),
    description:
      "朝食を食べる時間がなく、昼食を食べると眠くなってしまうので、BASE FOODを食べはじめました。朝食を食べることで、昼食までの間にお腹がすかなくなりました。ママ友にもおすすめしています！",
  },
];

export function MemberVoiceSection(): React.ReactNode {
  return (
    <section className="bg__gray pd__top__xl">
      <div className="container">
        <div className="row">
          <div className="col-12 mg__bottom__xl">
            <h4 className="text__xl text__bold text__center mg__bottom__l">
              わたしたちも
              <br />
              <span className={styles.nowrap}>友だち紹介制度を</span>
              <wbr />
              <span className={styles.nowrap}>つかっています！</span>
            </h4>
          </div>
          {voices.map((voice, index) => (
            <div key={index} className="col-12 col-m-4 mg__bottom__xl">
              <MemberVoice
                src={voice.src}
                alt={voice.alt}
                title={voice.title}
                description={voice.description}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MemberVoiceSection;
