"use client";

import { useCallback } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter } from "next/navigation";
import { Controller, useForm } from "react-hook-form";

import { LoadingOverlay } from "@/components";
import { getErrorMessages } from "@/utils/error";

import { useSendEmail } from "./helpers";
import styles from "./PasswordResetMailForm.module.scss";
import { PasswordResetMailFormSchema, PasswordResetMailFormSchemaValue } from "./schema";

export function PasswordResetMailForm(): React.ReactNode {
  const router = useRouter();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid, isDirty, isSubmitting, isSubmitSuccessful },
  } = useForm<PasswordResetMailFormSchemaValue>({
    resolver: zodResolver(PasswordResetMailFormSchema),
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });

  const { sendEmail } = useSendEmail();

  const cancel = useCallback(() => {
    router.push("/account/login");
  }, [router]);

  const onSubmit = async (formValues: PasswordResetMailFormSchemaValue) => {
    try {
      await sendEmail(formValues);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setError("root", { type: "custom", message: getErrorMessages(e).join("\n") });
    }
  };

  return (
    <div>
      {isSubmitSuccessful ? (
        <p className="text__m">
          パスワードをリセットするためのメールをお送りしました。メールボックスをご確認ください。
        </p>
      ) : (
        <LoadingOverlay isLoading={isSubmitting}>
          <>
            <p className="text__m mg__bottom__l">
              パスワードをリセットするためのメールを送ります。
            </p>
            {errors.root && (
              // TODO: idの使用用途不明
              <div className="errors" id="login_title_error">
                <div className="text__red">{errors.root.message}</div>
              </div>
            )}
            <form id="password-reset" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <>
                    <label className="text__m">メールアドレス</label>
                    <input type="email" className={styles.default} {...field} />
                  </>
                )}
              />
              {errors.email && <p className="mg__bottom__m text__red">{errors.email.message}</p>}
              <div className="text__center pd__top__m">
                <button
                  type="button"
                  className="text__black btn gray inline round text__m"
                  onClick={cancel}
                >
                  キャンセルする
                </button>
                <button
                  form="password-reset"
                  type="submit"
                  className="btn yellow inline round text__bold text__black text__m"
                  disabled={isSubmitting || !isDirty || !isValid}
                >
                  送信する
                </button>
              </div>
            </form>
          </>
        </LoadingOverlay>
      )}
    </div>
  );
}
